import { lazy } from 'react';
import { Module } from '../core/types';
import { Groups, HeadsetMic, Person, SupportAgent, } from '@mui/icons-material';

// ############### props from routes

import { dependencies as TicketsDep, options as TicketsOpt } from '../pages/HelpDesk/Chamados/route.props';
import { dependencies as ClientesDep } from '../pages/Comercial/Clientes/route.props';
import { options as CLientesOpt } from '../pages/Comercial/Clientes/route.props';



// ############### Elements of Routes

const Chamados = lazy(() => import('../pages/HelpDesk/Chamados'));
const Clientes = lazy(() => import('../pages/Comercial/Clientes'));
const GruposClientes = lazy(() => import('../pages/Comercial/Grupos'));

const Situacoes = lazy(() => import('../pages/HelpDesk/Situacoes'));
const TicketTypes = lazy(() => import('../pages/HelpDesk/TiposChamados'));

// ############### Routes

export const BR_HelpDesk: Module = {
    name: 'helpdesk',
    ignoreDahsboard: true,
    routes: [
        {
            name: 'helpdesk', icon: <HeadsetMic />,
            children: [
                { name: 'chamados', element: <Chamados />, icon: <SupportAgent />, resource: 'chamados', dependencies: TicketsDep, options: TicketsOpt },
                { name: 'clientes', element: <Clientes path="clientes" group='helpdesk' />, icon: <Person />, resource: 'pessoas', dependencies: ClientesDep, options: CLientesOpt },
                { name: 'gruposclientes', element: <GruposClientes />, icon: <Groups />, resource: 'gruposclientes' },
                { name: 'tiposchamados', element: <TicketTypes />, resource: 'tiposchamados' },
                { name: 'situacoes', element: <Situacoes />, resource: 'situacoeschamados' },
            ]
        },
    ],

}