export const options = ['PodeQuitarConta'];
export const dependencies = [
    { resource: 'pessoas', permissions: { list: true } },
    { resource: 'contas', permissions: { list: true } },
    { resource: 'metodospagamento', permissions: { list: true } },
    { resource: 'categoriasfinanceiras', permissions: { list: true } },
    { resource: 'realizarlancamento', permissions: { list: true, record: true, insert: true, update: true } },
    { resource: 'reprocessacaixa', permissions: { list: true, record: true, insert: true, update: true } },

    { resource: 'reprocessacaixa', permissions: { list: true, record: true, insert: true, update: true } },
    { resource: 'boleto', permissions: { list: true, record: true, insert: true, update: true } },

];