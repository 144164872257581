import React from "react";
import { DialogModalContainerProps, DialogModalParams, DialogModalOption, ProviderModalContext } from './types';
// import { useLang } from "../Lang";
import DialogModal from "../../components/ui/DialogModal";
import { useNavigate } from "react-router-dom";
import { useTheme as useThemeCore } from '@material-ui/core/styles';
import { useMediaQuery } from "@material-ui/core";

const EMPTY_FUNC = () => { };
const DialogModalContext = React.createContext<ProviderModalContext>([EMPTY_FUNC, EMPTY_FUNC]);

export const useDialogModal = () => React.useContext(DialogModalContext);


const DialogModalContainer = (props: DialogModalContainerProps) => {
    const { open, onClose, onExited, title, content, size, hash, onSubmit, classes, buttons, noCloseButton } = props;
    const themeCore = useThemeCore();
    const isMobile = useMediaQuery(themeCore.breakpoints.down('xs'));
    const isMedium = useMediaQuery(themeCore.breakpoints.down('sm'));


    const setOpen = (o: boolean) => {
        if (!o) {
            if (onClose)
                onClose();
            if (onExited)
                onExited();
        }
    }

    return <DialogModal noCloseButton={noCloseButton} hash={hash} caption={title} buttons={buttons} open={open} setOpen={setOpen} classes={classes} onSubmit={onSubmit} size={(isMobile ? 'large' : ((isMedium && size === undefined ? 'medium' : size)) || 'medium') }>
        {content}
    </DialogModal>
}

const DialogModalProvider: React.FC<object> = ({ children }) => {

    const navigate = useNavigate();
    const [dialogs, setDialogs] = React.useState<DialogModalParams[]>([]);

    const createDialog = (option: DialogModalOption) => {
        const dialog = { ...option, open: true };
        setDialogs((dialogs) => [...dialogs, dialog]);
    };

    const closeDialog = () => {

        setDialogs((dialogs) => {
            const latestDialog = dialogs.pop();
            if (!latestDialog)
                return dialogs;
            if (latestDialog.onClose)
                latestDialog.onClose();
            return [...dialogs].concat({ ...latestDialog, open: false });
        });
    };

    const contextValue = React.useRef([createDialog, closeDialog] as const);

    return (
        <DialogModalContext.Provider value={contextValue.current}>
            {children}

            {dialogs.map((dialog, i) => {
                const { onClose, hash, ...dialogParams } = dialog;
                const handleKill = () => {
                    if (hash)
                        navigate(window.location.pathname, {replace: true});
                    // window.history.back();
                    if (dialog.onExited)
                        dialog.onExited();
                    setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
                };

                return (
                    <DialogModalContainer
                        key={i}
                        hash={hash}
                        onClose={closeDialog}
                        onExited={handleKill}
                        {...dialogParams}
                    />
                );
            })}
        </DialogModalContext.Provider>
    );
}


export default DialogModalProvider;