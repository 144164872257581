import { Backdrop, Box, Button, Card, CircularProgress, Grid, IconButton, Modal, Typography } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import React, { Dispatch, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { DialogModalButton, DialogModalSize } from '../../../services/DialogModal/types';
import { useLang } from '../../../services/Lang';
import Container from '../Container';
import useStyles from './style';

interface IDialogModal {
    ref?: any;
    hash: string;
    caption: string;
    open: boolean;
    setOpen: Dispatch<boolean>;
    classes: any;
    size?: DialogModalSize;
    onSubmit?: () => any;
    autoClose?: boolean;
    innerScroll?: boolean;
    buttons?: DialogModalButton[];
    fullHeight?: boolean;
    noCloseButton?: boolean;
}

const DialogModal: React.FC<IDialogModal> = ({ ref, hash, caption, open, setOpen, onSubmit, classes, size, children, autoClose, innerScroll,
    buttons, fullHeight, noCloseButton }) => {

    const { lang } = useLang();
    // const navigate = useNavigate();
    const dialogClasses = useStyles();

    const [isOpen, setIsOpen] = useState(window.location.hash === hash);

    const btnOk = !buttons || buttons.includes('ok');
    const btnCancel = !buttons || buttons.includes('cancel');
    const btnClose = !!buttons && buttons.includes('close');
    const [submitting, setSubmitting] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (open) {
            window.location.hash = hash;

        } else if (window.location.hash === '#' + hash) {
            handleClose()
            window.history.back();
        } else {
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const handleClose = () => {
        setSubmitting(false);
        setOpen(false);
    };

    const onCancel = () => {
        handleClose();
    }

    useEffect(() => {
        const onHashChange = () => {
            const o = window.location.hash === '#' + hash;
            if (!o) {
                handleClose()
            }
            setIsOpen(o);
            setOpen(o)
        };
        window.addEventListener("hashchange", onHashChange);
        return () => {
            window.removeEventListener("hashchange", onHashChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getClass = (): string => {
        switch (size) {
            case "small":
                return dialogClasses.ModalSmall
            case "medium":
                return dialogClasses.ModalMedium
            case "large":
                return dialogClasses.ModalLarge;
            case "full":
                return dialogClasses.ModalFull;
        }
        return dialogClasses.ModalCustom
    }

    const okClick = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setSubmitting(true);
        e?.preventDefault();
        if (onSubmit) {
            try {
                if ((await onSubmit()) === false) {
                    setSubmitting(false);
                    return;
                }
            } catch (err) {
                setSubmitting(false);
                return;
            }

        }
        if (autoClose !== false)
            handleClose();
    }

    return (
        <Modal
            ref={ref}
            open={isOpen}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 250, onClick: (evt) => { evt.preventDefault(); } }}
            disableScrollLock
            className={classes?.Modal}
            onKeyPress={(event) => {
                if (event.key === 'Enter') {
                    okClick()
                }
            }}
        >
            <Box className={getClass()} style={{ width: typeof size === 'number' ? size : undefined }}>
                <Card className={dialogClasses.Card}>
                    <Box className={dialogClasses.Header + ' ' + (innerScroll ? dialogClasses.InnerScroll : '')} id="dialogModalHeader">
                        <Typography variant="h3">{caption}</Typography>
                        {noCloseButton ? undefined : <IconButton aria-label="Close" size="small" className={innerScroll ? dialogClasses.InnerScrollCloseBtn : dialogClasses.CloseBtn} onClick={onCancel}><Close /></IconButton>}
                    </Box>
                    <Container className={(innerScroll === false ? dialogClasses.ContainerModalNoScroll : dialogClasses.ContainerModal) + ' ' + (fullHeight ? dialogClasses.ContainerFullHeight : '') + ' ' + (size === 'full' ? dialogClasses.ContainerFull : '')}>
                        {children}
                    </Container>
                    {(btnOk || btnCancel || btnClose) && <Grid container className={dialogClasses.Bottom} >
                        {btnOk && <Button onClick={okClick} color="secondary" variant="contained" disabled={submitting}>{submitting && <CircularProgress size={14} style={{ marginRight: 6 }} />} {lang.data.captions.ok}</Button>}
                        {btnCancel && <Button onClick={onCancel} variant="outlined" disabled={submitting}>{lang.data.captions.cancel} </Button>}
                        {btnClose && <Button onClick={onCancel} variant="outlined" disabled={submitting}>{lang.data.captions.close} </Button>}
                    </Grid>}
                </Card>
            </Box>
        </Modal>
    );
}

export default DialogModal;