export const optionsVendas = ['PermitirImprimir', 'SomentePedidosVendedor', 'PodeMudarPrecoVenda', 'ReprocessarRecebimentos', 'SomenteVendasParaEntrega', 'PodeRemoverAssinatura', 'MetodoPagtoObrigatorio',
    'BloquearMetodoPagtoVenda', 'MostrarProdutosSemEstoque', 'PodeSelecionarEstoque', 'BloquearDataVenda', ];
export const optionsPedidos = ['PermitirImprimir', 'SomentePedidosVendedor', 'PodeConfirmarPedido', 'PodeEfetivarAbaixoLucroMinimo', 'PodeMudarPrecoVenda', 'MetodoPagtoObrigatorio',
    'BloquearMetodoPagtoVenda', 'MostrarProdutosSemEstoque', 'PodeSelecionarEstoque', 'PodeSelecionarLote', 'BloquearDataVenda', ];
export const dependencies = [
    { resource: 'produtos', permissions: { list: true } },
    { resource: 'produtoslotes', permissions: { list: true } },
    { resource: 'produtoscatalogo', permissions: { list: true } },
    { resource: 'produtoscatalogoest', permissions: { list: true } },
    { resource: 'produtospesquisa', permissions: { list: true } },
    { resource: 'produtospesquisaest', permissions: { list: true } },
    { resource: 'gruposprodutos', permissions: { list: true } },
    { resource: 'categoriasprodutos', permissions: { list: true } },
    { resource: 'estoques', permissions: { list: true } },
    { resource: 'pessoas', permissions: { list: true } },
    { resource: 'pessoaendereco', permissions: { list: true } },
    { resource: 'clonarmovestoque', permissions: { list: true, record: true, insert: true, update: true } },
    { resource: 'recebepedido', permissions: { list: true, record: true, insert: true, update: true } },
    { resource: 'entregarpedido', permissions: { list: true, record: true, insert: true, update: true } },
    { resource: 'devolucoes', permissions: { list: true, } },
    { resource: 'rotasvenda', permissions: { list: true, } },
    { resource: 'embalagens', permissions: { list: true, } },
    { resource: 'movimentosestoqueembalagens', permissions: { list: true } },
    { resource: 'tabelaspreco', permissions: { list: true } },
    { resource: 'movestoqueresumo', permissions: { list: true } },
    { resource: 'movestoquepeso', permissions: { list: true } },
    { resource: 'movestoqueespeciais', permissions: { list: true } },
    { resource: 'movestoquepessoas', permissions: { list: true } },
    { resource: 'metodospagamento', permissions: { list: true } },

];