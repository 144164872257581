import { lazy } from 'react';
import { Module } from '../core/types';
import { Person, ShoppingBasket, Assignment, ShoppingBasketOutlined, Groups } from '@mui/icons-material';

// ############### props from routes

import { options as CustomersOptions, dependencies as CustomersDep } from '../pages/Comercial/Clientes/route.props';
import { dependencies as PricingTablesDep } from '../pages/Comercial/TabelasPreco/route.props';

import { optionsPedidos as OrdersOptions, optionsVendas as InvoicesOptions, dependencies as OrdersDep } from '../pages/Comercial/Vendas/route.props';

// ############### Elements of Routes

const Vendas = lazy(() => import('../pages/Comercial/Vendas'));
const Clientes = lazy(() => import('../pages/Comercial/Clientes'));
const GruposClientes = lazy(() => import('../pages/Comercial/Grupos'));
const TabelasPreco = lazy(() => import('../pages/Comercial/TabelasPreco'));
const Comissoes = lazy(() => import('../pages/Comercial/Comissoes'));

// ############### Routes

export const BR_Comercial: Module = {
    name: 'comercial',
    showsif: ['estoque'],
    routes: [
        {
            name: 'comercial', icon: <ShoppingBasket />,
            children: [
                { name: 'vendas', element: <Vendas key="vendas" path='vendas' grupo='comercial' clientesPath='clientes' />, icon: <ShoppingBasketOutlined />, resource: 'movimentosestoque', dependencies: OrdersDep, options: InvoicesOptions, },
                { name: 'pedidos', element: <Vendas key="pedidos" path='pedidos' grupo='comercial' clientesPath='clientes' />, icon: <Assignment />, resource: 'movimentosestoque', dependencies: OrdersDep, options: OrdersOptions, },
                { name: 'clientes', element: <Clientes path="clientes" group="comercial" />, icon: <Person />, resource: 'pessoas', dependencies: CustomersDep, options: CustomersOptions },
                { name: 'gruposclientes', element: <GruposClientes />, icon: <Groups />, resource: 'gruposclientes' },
                { name: 'tabelaspreco', element: <TabelasPreco />, resource: 'tabelaspreco', dependencies: PricingTablesDep, },
                { name: 'comissoes', element: <Comissoes />, resource: 'comissoes', internalGroup: 'comercial', internalName: 'comissoes' },
            ]
        }
    ]
}

