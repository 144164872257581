import { lazy } from 'react';
import { Module } from '../core/types';
import { MonetizationOn, AccountBalance } from '@mui/icons-material';

// ############### props from routes

import { options as TransactionsOptions, dependencies as TransactionsDep } from '../pages/Financeiro/Lancamentos/route.props';
import { dependencies as PaymentsDep } from '../pages/Financeiro/MetodosPagamento/route.props';

// ############### Contents of Routes

const Lancamentos = lazy(() => import('../pages/Financeiro/Lancamentos'));
const MetodosPagamento = lazy(() => import('../pages/Financeiro/MetodosPagamento'));
const Contas = lazy(() => import('../pages/Financeiro/Contas'));
const Categorias = lazy(() => import('../pages/Financeiro/Categorias'));

// ############### Routes

export const US_Banking: Module = {
    name: 'banking',
    routes: [
        {
            name: 'banking', icon: <AccountBalance />, internalName: 'financeiro',
            children: [
                { name: 'transactions',     element: <Lancamentos />, icon: <MonetizationOn />, resource: 'lancamentoscaixa', options: TransactionsOptions, dependencies: TransactionsDep, internalGroup: 'financeiro', internalName: 'lancamentos' },
                { name: 'accounts',         element: <Contas />, resource: 'contas', internalGroup: 'financeiro', internalName: 'contas' },
                { name: 'payments',         element: <MetodosPagamento />, resource: 'metodospagamento', dependencies: PaymentsDep, internalGroup: 'financeiro', internalName: 'metodospagamento' },
                { name: 'categories',       element: <Categorias />, resource: 'categoriasfinanceiras', internalGroup: 'financeiro', internalName: 'categorias' },
            ]
        },
    ]
}