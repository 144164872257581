import { lazy } from 'react';
import { LocalShipping, Map } from '@mui/icons-material';
import { Module } from '../core/types';

// ############### props from routes

import { dependencies as SalesRouteDep, options as SalesRouteOpts } from '../pages/Logistica/RotasVenda/route.props';

// ############### Elements of Routes

const RotasVenda = lazy(() => import('../pages/Logistica/RotasVenda'));
const Pacotes = lazy(() => import('../pages/Logistica/Pacotes'));

// ############### Routes

export const BR_Logistica: Module = {
    name: 'logistica',
    routes: [
        {
            name: 'logistica', icon: <LocalShipping />,
            children: [
                { name: 'rotasvenda', element: <RotasVenda />, icon: <Map />, resource: 'rotasvenda', dependencies: SalesRouteDep, options: SalesRouteOpts },
                { name: 'pacotes', element: <Pacotes />, resource: 'embalagens' },
            ]
        },

    ],
}