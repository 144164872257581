import { lazy } from 'react';
import { Module } from '../core/types';
import {  IntegrationInstructions, RocketLaunch, } from '@mui/icons-material';

// ############### props from routes

import { dependencies as VersionsDep } from '../pages/Projetos/Versoes/route.props';
import { dependencies as ProductsDep } from '../pages/Estoque/Produtos/route.props';


// ############### Elements of Routes

const Versoes = lazy(() => import('../pages/Projetos/Versoes'));
const Produtos = lazy(() => import('../pages/Estoque/Produtos'));
const Grupos = lazy(() => import('../pages/Estoque/Grupos'));
const Categorias = lazy(() => import('../pages/Estoque/Categorias'));

// ############### Routes

export const BR_Projetos: Module = {
    name: 'projetos',
    ignoreDahsboard: true,
    routes: [
        {
            name: 'projetos', icon: <IntegrationInstructions />,
            children: [
                { name: 'versoes', element: <Versoes />, icon: <RocketLaunch />, resource: 'versoes', dependencies: VersionsDep },
                { name: 'produtos', element: <Produtos grupo='contratos' />, resource: 'produtos', dependencies: ProductsDep },
                { name: 'grupos', element: <Grupos grupo='contratos' />, resource: 'gruposprodutos', },
                { name: 'categorias', element: <Categorias grupo='contratos' />, resource: 'categoriasprodutos' },
            ]
        },

    ],

}