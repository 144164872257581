export const options = ['PodeAlterarDadosContrato', 'PodeModificarContratosAtivos'];

export const dependencies = [
    { resource: 'tiposcontratos', permissions: { list: true } },
    { resource: 'pessoas', permissions: { list: true } },
    { resource: 'contas', permissions: { list: true } },
    { resource: 'metodospagamento', permissions: { list: true } },
    { resource: 'categoriasfinanceiras', permissions: { list: true } },
    { resource: 'convenios', permissions: { list: true } },
    { resource: 'gerarcontratos', permissions: { list: true, insert: true, update: true, } },
    { resource: 'ajustarcontratos', permissions: { list: true, insert: true, update: true, } },
];