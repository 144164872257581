import { lazy } from 'react';
import { Module } from '../core/types';
import { Person, LocalOffer, ShoppingBasket, AllInbox, Assignment, ShoppingBasketOutlined, Inventory, CompareArrows, Groups } from '@mui/icons-material';

// ############### props from routes

import { options as CustomersOptions, dependencies as CustomersDep } from '../pages/Comercial/Clientes/route.props';
import { dependencies as PricingTablesDep } from '../pages/Comercial/TabelasPreco/route.props';

import { dependencies as ProductsDep, options as ProductOptions } from '../pages/Estoque/Produtos/route.props';
import { dependencies as ComprasDep } from '../pages/Estoque/Compras/route.props';
import { optionsPedidos as OrdersOptions, optionsVendas as InvoicesOptions, dependencies as OrdersDep } from '../pages/Comercial/Vendas/route.props';

// ############### Elements of Routes

const Vendas = lazy(() => import('../pages/Comercial/Vendas'));
const Customers = lazy(() => import('../pages/Comercial/Clientes'));
const CustomersGroups = lazy(() => import('../pages/Comercial/Grupos'));
const PricingTables = lazy(() => import('../pages/Comercial/TabelasPreco'));
const Commissions = lazy(() => import('../pages/Comercial/Comissoes'));

const Produtos = lazy(() => import('../pages/Estoque/Produtos'));
const Grupos = lazy(() => import('../pages/Estoque/Grupos'));
const Categorias = lazy(() => import('../pages/Estoque/Categorias'));
const Estoques = lazy(() => import('../pages/Estoque/Estoques'));
const Compras = lazy(() => import('../pages/Estoque/Compras'));
const Fornecedores = lazy(() => import('../pages/Estoque/Fornecedores'));

// ############### Routes

export const US_Stock: Module = {
    name: 'stock',
    routes: [
        {
            name: 'sales', icon: <ShoppingBasket />, internalName: 'comercial',
            children: [
                { name: 'invoices',         element: <Vendas key="vendas" path='vendas' grupo='comercial' clientesPath='customers' />, icon: <ShoppingBasketOutlined />, resource: 'movimentosestoque', dependencies: OrdersDep, options: InvoicesOptions, internalGroup: 'comercial', internalName: 'vendas', },
                { name: 'orders',           element: <Vendas key="pedidos" path='pedidos' grupo='comercial' clientesPath='customers' />, icon: <Assignment />, resource: 'movimentosestoque', dependencies: OrdersDep, options: OrdersOptions, internalGroup: 'comercial', internalName: 'pedidos', },
                { name: 'customers',        element: <Customers path="clientes" group="comercial" />, icon: <Person />, resource: 'pessoas', dependencies: CustomersDep, options: CustomersOptions, internalGroup: 'comercial', internalName: 'clientes' },
                { name: 'customersgroups',  element: <CustomersGroups />, icon: <Groups />, resource: 'gruposclientes', internalGroup: 'comercial', internalName: 'gruposclientes' },
                { name: 'pricingtables',    element: <PricingTables />, resource: 'tabelaspreco', dependencies: PricingTablesDep, internalGroup: 'comercial', internalName: 'tabelaspreco' },
                { name: 'commissions',      element: <Commissions />, resource: 'comissoes', internalGroup: 'comercial', internalName: 'comissoes' },
            ]
        },
        {
            name: 'stock', icon: <LocalOffer />, internalName: 'estoque',
            children: [
                { name: 'purchases',    element: <Compras key="compras" grupo='estoque' path='compras' />, icon: <AllInbox />, resource: 'movimentosestoque', dependencies: ComprasDep, internalGroup: 'estoque', internalName: 'compras' },
                { name: 'products',     element: <Produtos grupo='estoque' />, resource: 'produtos', dependencies: ProductsDep, options: ProductOptions, internalGroup: 'estoque', internalName: 'produtos' },
                { name: 'suppliers',    element: <Fornecedores />, resource: 'pessoas', internalGroup: 'estoque', internalName: 'fornecedores', },
                { name: 'groups',       element: <Grupos grupo='estoque' />, resource: 'gruposprodutos', internalGroup: 'estoque', internalName: 'grupos', },
                { name: 'categories',   element: <Categorias grupo='estoque' />, resource: 'categoriasprodutos', internalGroup: 'estoque', internalName: 'categorias',  },
                { name: 'stocks',       element: <Estoques />, resource: 'estoques', internalGroup: 'estoque', internalName: 'estoques',  },
                { name: 'inventories',    element: <Compras key="inventarios" grupo='estoque' path='inventarios' />, icon: <Inventory />, resource: 'movimentosestoque', dependencies: ComprasDep, internalGroup: 'estoque', internalName: 'inventarios',  },
                { name: 'transfers',    element: <Compras key="transferencias" grupo='estoque' path='transferencias' />, icon: <CompareArrows />, resource: 'movimentosestoque', dependencies: ComprasDep, internalGroup: 'estoque', internalName: 'transferencias',  },
                { name: 'stockloss',    element: <Compras key="perdas" grupo='estoque' path='perdas' />, resource: 'movimentosestoque', dependencies: ComprasDep, internalGroup: 'estoque', internalName: 'perdas',  },
            ]
        },
    ]
}

