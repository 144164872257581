import { ModuleArray } from "../core/types";

import { US_Stock } from "./us_stock";
import { US_Production } from "./us_production";
import { US_Logistics } from './us_logistics';
import { US_Banking } from "./us_banking";
import { US_Settings } from "./us_settings";

import { BR_Comercial } from "./br_comercial";
import { BR_HelpDesk } from "./br_helpdesk";
import { BR_Servicos } from "./br_servicos";
import { BR_Estoque } from "./br_estoque";
import { BR_Fiscal } from "./br_fiscal";
import { BR_Logistica } from './br_logistica';
import { BR_Producao } from "./br_producao";
import { BR_Contratos } from "./br_contratos";
import { BR_Financeiro } from "./br_financeiro";
import { BR_Configuracoes } from "./br_configuracoes";
import { BR_Projetos } from "./br_projetos";


export const modules: ModuleArray = [
    US_Stock,
    US_Production,
    US_Logistics,
    US_Banking,
    US_Settings,

    BR_Comercial,
    BR_HelpDesk,
    BR_Servicos,
    BR_Fiscal,
    BR_Projetos,
    BR_Contratos,
    BR_Estoque,
    BR_Producao,
    BR_Logistica,
    BR_Financeiro,
    BR_Configuracoes,
];

export default modules;