import { lazy } from 'react';
import { AssignmentTurnedIn, Groups, Handyman, Person } from '@mui/icons-material';
import { Module } from '../core/types';

// ############### props from routes

import { dependencies as OrdensServicosDep, options as OrdensServicosOpt } from '../pages/Servicos/OrdensServico/route.props';
import { dependencies as ServicosDep, options as ServicosOpt } from '../pages/Servicos/Servicos/route.props';
import { options as CustomersOptions, dependencies as CustomersDep } from '../pages/Comercial/Clientes/route.props';

// ############### Elements of Routes

const OrdensServico = lazy(() => import('../pages/Servicos/OrdensServico'));

const Servicos = lazy(() => import('../pages/Servicos/Servicos'));
const Clientes = lazy(() => import('../pages/Comercial/Clientes'));
const GruposClientes = lazy(() => import('../pages/Comercial/Grupos'));
const Comissoes = lazy(() => import('../pages/Comercial/Comissoes'));

// ############### Routes

export const BR_Servicos: Module = {
    name: 'servicos',
    routes: [
        {
            name: 'servicos', icon: <Handyman />,
            children: [
                { name: 'ordensservico', element: <OrdensServico />, icon: <AssignmentTurnedIn />,resource: 'ordensservico', dependencies: OrdensServicosDep, options: OrdensServicosOpt, },
                { name: 'servicos', element: <Servicos grupo='servicos' />, resource: 'servicos', dependencies: ServicosDep, options: ServicosOpt, },

                { name: 'clientes', element: <Clientes path="clientes" group="comercial" />, icon: <Person />, resource: 'pessoas', dependencies: CustomersDep, options: CustomersOptions, ifDoesntHaveModules: ['estoque', 'helpdesk'] },
                { name: 'gruposclientes', element: <GruposClientes />, icon: <Groups />, resource: 'gruposclientes', ifDoesntHaveModules: ['estoque', 'helpdesk'] },
                { name: 'comissoes', element: <Comissoes />, resource: 'comissoes', internalGroup: 'comercial', internalName: 'comissoes', ifDoesntHaveModules: ['estoque'] },
            ]
        },

    ],
}