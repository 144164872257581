import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        Container: {
            // padding: theme.spacing(3),
            // [theme.breakpoints.down('md')]: {
            //     padding: theme.spacing(2),
            // },

            '& .BGRow:not(:first-child)': {
                paddingTop: theme.spacing(2),
            },

            '& .BGCol': {
                '& .BGRow:first-child': {
                    paddingTop: 0,
                },
                '& .BGRow': {
                    paddingTop: 8,
                }
            }

        },
    }),
);

export default useStyles;