import { CSSProperties, FC, ReactNode } from 'react';
import useStyles from './styles';

type Props = {
    visible?: boolean;
    children: ReactNode;
    style?: CSSProperties;
}

const ErrorBox: FC<Props> = ({ visible, children, style }: Props) => {
    const classes = useStyles();
    const visStyle: CSSProperties = !visible ? { visibility: 'hidden', minHeight: 0, padding: 0 } : {}
    return (
        <div
            className={classes.Box}
            style={{
                ...style,
                ...visStyle
            }}
        >
            {children}
        </div>
    );
}

export default ErrorBox;