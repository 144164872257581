import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { DialogOption, ProviderContext, DialogParams, DialogContainerProps, DialogButtonType, DialogButtonVariant, DialogButtonColor, DialogSize } from './types';
import ColorButton from "../../components/ui/ColorButton";
import { useLang } from "../Lang";
import { Close } from "@mui/icons-material";

const EMPTY_FUNC = () => { };
const DialogContext = React.createContext<ProviderContext>([EMPTY_FUNC, EMPTY_FUNC]);

export const useDialog = () => React.useContext(DialogContext);

export type DoCloseFunction = () => void;

export type DialogContentFuncion = (doClose: DoCloseFunction) => any;


const DialogContainer = (props: DialogContainerProps) => {
    const { open, onClose, onExited, title, content, buttons, captions, colors, icons, variants, size, onClickOk, onClickYes, onClickNo, onClickCancel } = props;
    const { lang } = useLang();
    const [btns] = useState<any>({});

    const doClickOk = () => {
        if (onClickOk) onClickOk();
        if (onClose) onClose();
    }
    const doClickYes = () => {
        if (onClickYes) onClickYes();
        if (onClose) onClose();
    }
    const doClickNo = () => {
        if (onClickNo) onClickNo();
        if (onClose) onClose();
    }
    const doClickCancel = () => {
        if (onClickCancel) onClickCancel();
        if (onClose) onClose();
    }

    let _buttons: DialogButtonType[] = ['ok'];
    if (buttons)
        _buttons = buttons;
    let _captions: string[] = [];
    if (captions)
        _captions = captions;
    let _variants: DialogButtonVariant[] = [];
    if (variants)
        _variants = variants;
    let _colors: DialogButtonColor[] = [];
    if (colors)
        _colors = colors;
    let _icons: React.ReactNode[] = [];
    if (icons)
        _icons = icons;
    let _size: DialogSize = 'sm';
    if (size)
        _size = size;

    const getButtons = () => {
        return _buttons.map((b, i) => {
            let variant = _variants[i];
            if (!variant)
                variant = 'outlined';
            let color = _colors[i];
            if (!color)
                color = 'default';
            const icon = _icons[i];
            let caption: string = lang.data.captions.ok;
            let click = doClickOk;
            switch (b) {
                case 'yes':
                    btns.yes = true;
                    caption = lang.data.captions.yes;
                    click = doClickYes;
                    break;
                case 'no':
                    btns.no = true;
                    caption = lang.data.captions.no;
                    click = doClickNo;
                    break;
                case 'cancel':
                    btns.cancel = true;
                    caption = lang.data.captions.cancel;
                    click = doClickCancel;
                    break;
                case 'ok':
                    btns.ok = true;
                    break;
            }
            if (_captions[i])
                caption = _captions[i];
            return <ColorButton className={'BGDialog_' + b}  key={i} color={color} variant={variant} onClick={click} icon={icon}>{caption}</ColorButton>
        })
    }

    const onKeyDown = (evt: React.KeyboardEvent<HTMLElement>) => {
        if (evt.key === 'Enter') {
            if (btns.ok)
                return doClickOk();
            if (btns.yes)
                return doClickYes();
        } else if (evt.key === 'Escape') {
            if (btns.cancel)
                return doClickCancel();
            if (btns.no)
                return doClickNo();
            return doClickCancel();
        }
    }


    return (
        <Dialog open={open} onClose={doClickCancel} TransitionProps={{ onExited }} fullWidth={true} maxWidth={_size}
            onKeyDown={onKeyDown}>
            {title ? <DialogTitle disableTypography={true} style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 2,}}>
                <h3>
                    {title}
                </h3>
                <IconButton aria-label="Close" size="small" onClick={doClickCancel}><Close /></IconButton>
            </DialogTitle> : undefined}

            <DialogContent>
                {typeof content === 'function' ? content(doClickCancel) : content}
            </DialogContent>

            {buttons?.length ? <DialogActions>
                {getButtons()}
            </DialogActions> : undefined}
        </Dialog>
    );
}

const DialogProvider: React.FC<object> = ({ children }) => {

    const [dialogs, setDialogs] = React.useState<DialogParams[]>([]);

    const createDialog = (option: DialogOption) => {
        const dialog = { ...option, open: true };
        setDialogs((dialogs) => [...dialogs, dialog]);
    };

    const closeDialog = () => {
        setDialogs((dialogs) => {
            const latestDialog = dialogs.pop();
            if (!latestDialog)
                return dialogs;
            if (latestDialog.onClose)
                latestDialog.onClose();
            return [...dialogs].concat({ ...latestDialog, open: false });
        });
    };

    const contextValue = React.useRef([createDialog, closeDialog] as const);

    return (
        <DialogContext.Provider value={contextValue.current}>
            {children}

            {dialogs.map((dialog, i) => {
                const { onClose, onExited, ...dialogParams } = dialog;
                const handleKill = () => {
                    if (dialog.onExited) dialog.onExited();
                    setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
                };

                return (
                    <DialogContainer
                        key={i}
                        onClose={closeDialog}
                        onExited={handleKill}
                        {...dialogParams}
                    />
                );
            })}
        </DialogContext.Provider>
    );
}


export default DialogProvider;