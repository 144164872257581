import { lazy } from 'react';
import { Discount as LabelIcon, Settings } from '@mui/icons-material';
import { Module } from '../core/types';

import { dependencies as UsersDep } from '../pages/Configuracoes/Usuarios/route.props';
import { dependencies as OptionsDep } from '../pages/Configuracoes/Parametros/route.props';

// ############### Elements of Routes

const Etiquetas = lazy(() => import('../pages/Configuracoes/Etiquetas'));
const Funcionarios = lazy(() => import('../pages/Configuracoes/Funcionarios'));
const Usuarios = lazy(() => import('../pages/Configuracoes/Usuarios'));
const Parametros = lazy(() => import('../pages/Configuracoes/Parametros'));


// ############### Routes

export const BR_Configuracoes: Module = {
    name: 'configuracoes',
    public: true,
    routes: [
        {
            name: 'configuracoes',
            icon: <Settings />,
            children: [
                { name: 'etiquetas', element: <Etiquetas />, icon: <LabelIcon />, resource: 'etiquetas', ifHaveModules: ['estoque'] },
                { name: 'funcionarios', element: <Funcionarios />, resource: 'pessoas' },
                { name: 'usuarios', element: <Usuarios key='usuarios' path='usuarios' />, resource: 'usuarios', dependencies: UsersDep },
                { name: 'gruposusuarios', element: <Usuarios key='gruposusuarios' path='gruposusuarios' />, resource: 'usuarios' },
                { name: 'parametros', element: <Parametros />, readOnly: true, dependencies: OptionsDep },
            ]
        },

    ],
    showsif: ['estoque', 'contratos']

}