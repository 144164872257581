import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CustomTheme } from '../../../services/Theme/types';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        Box: {
            textAlign: 'center',
            minHeight: 56,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.others.negativeBackground,
            color: theme.others.negativeValue,
            fontWeight: 400,
            fontSize: '1rem',
            borderRadius: 6,
            border: '1px solid ' + theme.others.negativeValue,
            padding: 12
        },
    }),
);

export default useStyles;