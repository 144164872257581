import { lazy } from 'react';
import { Handyman, Task } from '@mui/icons-material';
import { Module } from '../core/types';

// ############### props from routes

import { dependencies as NFSeDep, options as NFseOpt } from '../pages/Fiscal/NFSe/route.props';

// ############### Elements of Routes

const NFSe = lazy(() => import('../pages/Fiscal/NFSe'));

// ############### Routes

export const BR_Fiscal: Module = {
    name: 'fiscal',
    routes: [
        {
            name: 'fiscal', icon: <Task />,
            children: [
                { name: 'nfse', element: <NFSe />, icon: <Handyman />, resource: 'nfse', dependencies: NFSeDep, options: NFseOpt, },
            ]
        },

    ],
}