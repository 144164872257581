import React from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './styles';

interface IContainer {
    className?: string;
 }
const Container: React.FC<IContainer> = ({ children, className }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.Container + ' ' + className}>
            {children}
        </Grid>
    );
}

export default Container;