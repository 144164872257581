import { lazy } from 'react';
import { Module } from '../core/types';
import { Widgets } from '@mui/icons-material';

// ############### props from routes
import { dependencies as ComprasDep } from '../pages/Estoque/Compras/route.props';

// ############### Elements of Routes
const Compras = lazy(() => import('../pages/Estoque/Compras'));
const Grupos = lazy(() => import('../pages/Producao/Grupos'));

// ############### Routes

export const BR_Producao: Module = {
    name: 'producao',
    routes: [
        {
            name: 'producao', icon: <Widgets />,
            children: [
                { name: 'producoes', element: <Compras key="producoes" langGroup='producao' grupo='producao' path='producoes' />, resource: 'movimentosestoque', dependencies: ComprasDep,  },
                { name: 'grupos', element: <Grupos />, resource: 'gruposproducao', },
            ]
        },
    ]
}

