export const dependencies = [
    { resource: 'produtos', permissions: { list: true } },
    { resource: 'gruposprodutos', permissions: { list: true } },
    { resource: 'categoriasprodutos', permissions: { list: true } },
    { resource: 'estoques', permissions: { list: true } },
    { resource: 'pessoas', permissions: { list: true } },
    { resource: 'clonarmovestoque', permissions: { insert: true, update: true } },
    { resource: 'sugestaocompra', permissions: { insert: true, update: true } },
    { resource: 'produtoscatalogo', permissions: { list: true } },
    { resource: 'produtospesquisa', permissions: { list: true } },
    { resource: 'produtoscodbar', permissions: { list: true } },
    { resource: 'movestoqueprodutosdet', permissions: { list: true } },
];