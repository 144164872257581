import { lazy } from 'react';
import { Module } from '../core/types';
import { LocalOffer, AllInbox, Inventory, CompareArrows } from '@mui/icons-material';

// ############### props from routes


import { dependencies as ProductsDep, options as ProductOptions } from '../pages/Estoque/Produtos/route.props';
import { dependencies as ComprasDep } from '../pages/Estoque/Compras/route.props';

// ############### Elements of Routes

const Produtos = lazy(() => import('../pages/Estoque/Produtos'));
const Grupos = lazy(() => import('../pages/Estoque/Grupos'));
const Categorias = lazy(() => import('../pages/Estoque/Categorias'));
const Estoques = lazy(() => import('../pages/Estoque/Estoques'));
const Compras = lazy(() => import('../pages/Estoque/Compras'));
const Fornecedores = lazy(() => import('../pages/Estoque/Fornecedores'));

// ############### Routes

export const BR_Estoque: Module = {
    name: 'estoque',
    routes: [
        {
            name: 'estoque', icon: <LocalOffer />,
            children: [
                { name: 'compras', element: <Compras key="compras" grupo='estoque' path='compras' />, icon: <AllInbox />, resource: 'movimentosestoque', dependencies: ComprasDep },
                { name: 'produtos', element: <Produtos grupo='estoque' />, resource: 'produtos', dependencies: ProductsDep, options: ProductOptions },
                { name: 'fornecedores', element: <Fornecedores />, resource: 'pessoas', },
                { name: 'gruposprodutos', element: <Grupos grupo='estoque' />, resource: 'gruposprodutos', },
                { name: 'categorias', element: <Categorias grupo='estoque' />, resource: 'categoriasprodutos' },
                { name: 'estoques', element: <Estoques />, resource: 'estoques' },
                { name: 'inventarios', element: <Compras key="inventarios" grupo='estoque' path='inventarios' />, icon: <Inventory />, resource: 'movimentosestoque', dependencies: ComprasDep },
                { name: 'transferencias', element: <Compras key="transferencias" grupo='estoque' path='transferencias' />, icon: <CompareArrows />, resource: 'movimentosestoque', dependencies: ComprasDep },
                { name: 'perdas', element: <Compras key="perdas" grupo='estoque' path='perdas' />, resource: 'movimentosestoque', dependencies: ComprasDep },
            ]
        },
    ]
}

