import { lazy } from 'react';
import { Module } from '../core/types';
import {  Link, RequestQuote, } from '@mui/icons-material';

// ############### props from routes


import { dependencies as ContractsDep, options as ContractsOpts } from '../pages/Contratos/Contratos/route.props';
import { dependencies as ContractTypesDep } from '../pages/Contratos/TiposContratos/route.props';

// ############### Elements of Routes

const Contratos = lazy(() => import('../pages/Contratos/Contratos'));
const TiposContratos = lazy(() => import('../pages/Contratos/TiposContratos'));

// ############### Routes

export const BR_Contratos: Module = {
    name: 'contratos',
    ignoreDahsboard: true,
    routes: [
        {
            name: 'contratos', icon: <RequestQuote />,
            children: [
                { name: 'contratos', element: <Contratos />, icon: <Link />, resource: 'contratos', dependencies: ContractsDep, options: ContractsOpts },
                { name: 'tiposcontratos', element: <TiposContratos />, resource: 'tiposcontratos', dependencies: ContractTypesDep },

            ]
        },
    ],

}