import { lazy } from 'react';
import { Module } from '../core/types';
import { Widgets } from '@mui/icons-material';

// ############### props from routes
import { dependencies as ComprasDep } from '../pages/Estoque/Compras/route.props';

// ############### Elements of Routes

const Compras = lazy(() => import('../pages/Estoque/Compras'));
const Grupos = lazy(() => import('../pages/Producao/Grupos'));

// ############### Routes

export const US_Production: Module = {
    name: 'production',
    routes: [
        {
            name: 'production', icon: <Widgets />, internalName: 'producao',
            children: [
                { name: 'productions', element: <Compras key="producoes" grupo='producao' path='producoes' />, resource: 'movimentosestoque', dependencies: ComprasDep, internalGroup: 'producao', internalName: 'producoes' },
                { name: 'groups', element: <Grupos />, resource: 'gruposproducao', internalGroup: 'producao', internalName: 'grupos' },
            ]
        },
    ]
}

