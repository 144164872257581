import { lazy } from 'react';
import { Discount as LabelIcon, Settings } from '@mui/icons-material';
import { Module } from '../core/types';

import { dependencies as UsersDep } from '../pages/Configuracoes/Usuarios/route.props';
import { dependencies as OptionsDep } from '../pages/Configuracoes/Parametros/route.props';

// ############### Elements of Routes

const Etiquetas = lazy(() => import('../pages/Configuracoes/Etiquetas'));
const Funcionarios = lazy(() => import('../pages/Configuracoes/Funcionarios'));
const Usuarios = lazy(() => import('../pages/Configuracoes/Usuarios'));
const Parametros = lazy(() => import('../pages/Configuracoes/Parametros'));


// ############### Routes

export const US_Settings: Module = {
    name: 'settings',
    public: true,
    routes: [
        {
            name: 'settings',
            internalName: 'configuracoes',
            icon: <Settings />,
            children: [
                { name: 'labels',       element: <Etiquetas />, icon: <LabelIcon />, resource: 'etiquetas', ifHaveModules: ['stock'], internalGroup: 'configuracoes',  internalName: 'etiquetas' },
                { name: 'employees',    element: <Funcionarios />, resource: 'pessoas', internalGroup: 'configuracoes',  internalName: 'funcionarios' },
                { name: 'users',        element: <Usuarios key='usuarios' path='usuarios' />, resource: 'usuarios', dependencies: UsersDep, internalGroup: 'configuracoes',  internalName: 'usuarios' },
                { name: 'usersgroups',  element: <Usuarios key='gruposusuarios' path='gruposusuarios' />, resource: 'usuarios', internalGroup: 'configuracoes',  internalName: 'gruposusuarios' },
                { name: 'options',      element: <Parametros />, readOnly: true, dependencies: OptionsDep, internalGroup: 'configuracoes',  internalName: 'parametros' },
            ]
        },

    ],
    showsif: ['stock']
}