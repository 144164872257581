import { Helmet } from 'react-helmet';
import { Box, Button, Card, CardActions, CardContent, Hidden, Typography } from '@material-ui/core';
import Config from '../../../config';
import { useState } from 'react';
import { useApi } from '../../services/Api';
import { useLang } from '../../services/Lang';
import Row from '../../components/ui/Row';
import Col from '../../components/ui/Col';
import { ReactComponent as Beach } from '../../../icons/beach.svg';
import { useTheme } from '../../services/Theme';

const SlowDown = () => {
    const [lastPath] = useState(() => localStorage.getItem('lastPath') || '/');
    const api = useApi();
    const { lang } = useLang();
    const { theme } = useTheme()

    const doReconnect = () => {
        localStorage.setItem('lastPath', '');
        window.location.pathname = lastPath;
    }

    return (
        <>
            <Helmet>
                <title>{Config.name}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta>
            </Helmet>
            <Row style={{ padding: 12, alignItems: 'center' }}>
                <Hidden smDown>
                    <Col sm={12} md={6} style={{ padding: 36, display: 'flex', justifyContent: 'center' }}>
                        <Beach style={{ maxWidth: 512 }} />
                    </Col>

                    <Col sm={12} md={6}>
                        <Typography style={{ margin: 24, fontSize: '5rem', lineHeight: 1 }}>Hey!! Slow down!!</Typography>
                        <Typography style={{ margin: 24, fontSize: '1.5rem', color: theme.theme.palette.text.secondary }}>Você está indo rápido demais! Que tal aguardar um tempo? Você poderá voltar a acessar o sistema em 10 segundos.</Typography>
                        <Button variant='contained' color='primary' style={{ margin: 24 }}>Voltar</Button>
                    </Col>
                </Hidden>

                <Hidden mdUp>
                    <Col sm={12} md={6} style={{ textAlign: 'center' }}>
                        <Typography style={{ margin: 24, fontSize: '3rem', lineHeight: 1 }}>Hey!! Slow down!!</Typography>
                        <Typography style={{ margin: 24, fontSize: '1.5rem', color: theme.theme.palette.text.secondary }}>Você está indo rápido demais! Que tal aguardar um tempo? Você poderá voltar a acessar o sistema em 10 segundos.</Typography>
                        <Button variant='contained' color='primary' style={{ margin: 24 }} onClick={doReconnect}>Voltar</Button>
                    </Col>
                    <Col sm={12} md={6} style={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                        <Beach style={{ maxWidth: 360, maxHeight: 360 }} />
                    </Col>
                </Hidden>
            </Row >
        </>
    )
};

export default SlowDown;
