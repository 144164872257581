import { lazy } from 'react';
import { LocalShipping, Map } from '@mui/icons-material';
import { Module } from '../core/types';

// ############### props from routes

import { dependencies as SalesRouteDep, options as SalesRouteOpts } from '../pages/Logistica/RotasVenda/route.props';

// ############### Elements of Routes

const SalesRoutes = lazy(() => import('../pages/Logistica/RotasVenda'));
const Packaging = lazy(() => import('../pages/Logistica/Pacotes'));

// ############### Routes

export const US_Logistics: Module = {
    name: 'logistics',
    routes: [
        {
            name: 'logistics', icon: <LocalShipping />, internalName: 'logistica',
            children: [
                { name: 'salesroutes', element: <SalesRoutes />, icon: <Map />, resource: 'rotasvenda', dependencies: SalesRouteDep, options: SalesRouteOpts, internalGroup: 'logistica', internalName: 'rotasvenda' },
                { name: 'packaging', element: <Packaging />, resource: 'embalagens', internalGroup: 'logistica', internalName: 'pacotes' },
            ]
        },

    ],
}