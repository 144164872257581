import React, { CSSProperties } from 'react';
import { Grid, GridSize } from '@material-ui/core';

interface ICol {
    lg?: GridSize;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    xl?: GridSize;
    visible?: boolean;
    style?: CSSProperties;
    className?: string;
}
const Col: React.FC<ICol> = ({ lg, xs, sm, md, xl, visible, children, style, className }) => {
    return (visible === undefined || visible) ? (
        <Grid item
            xs={xs || 12}
            sm={sm || xs || 12}
            md={md || sm || xs || 12}
            lg={lg || md || sm || xs || 12}
            xl={xl || lg || md || sm || xs || 12}
            classes={{ root: 'BGCol' }}
            style={style}
            className={className}>
            {children}
        </Grid>
    ) : <></>;
}

export default Col;