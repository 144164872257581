import React, { CSSProperties } from 'react';
import { Grid } from '@material-ui/core';

interface IRow {
    ref?: any;
    visible?: boolean;
    style?: CSSProperties;
    className?: string;
}
const Row: React.FC<IRow> = ({ visible, style, children, className,ref }) => {
    return (visible === undefined || visible)  ? (
        <Grid ref={ref} container spacing={2} classes={{root: 'BGRow'}} style={style} className={className}>
            {children}
        </Grid>
    ) : <></>;
}

export default Row;